@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=optional");
@import "react-loading-skeleton/dist/skeleton.css";
@import "react-toastify/dist/ReactToastify.css";
@import "@spheron/ui-library/dist/css/index.css";

html,
body {
  color: var(--base-para-text-color);
  background-color: var(--base-bg-color);
  font-family: "Inter", sans-serif;
}

.Toastify {
  position: relative;
  z-index: 99999;
}
.Toastify__toast {
  border-radius: 4px;
  z-index: 9999999999;
  padding: 8px !important;
}
.Toastify__toast-body {
  padding: 0px !important;
}
.Toastify__close-button,
.Toastify__close-button--colored {
  color: var(--feedback-default-cross);
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  padding: var(--spacing-4) !important;
  background: var(--feedback-error-bg);
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  padding: var(--spacing-4) !important;
  background: var(--feedback-info-bg);
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  padding: var(--spacing-4) !important;
  background: var(--feedback-success-bg);
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  padding: var(--spacing-4) !important;
  background: var(--feedback-warning-bg);
}

.react-loading-skeleton {
  --base-color: var(--color-neutral-neutral-strong-neutral5) !important;
  --highlight-color: var(--color-neutral-neutral-strong-neutral20) !important;
}
.dark .react-loading-skeleton {
  --base-color: var(--color-neutral-neutral-strong-neutral200) !important;
  --highlight-color: var(--color-neutral-neutral-strong-neutral400) !important;
}

.leaderboard-table-hover tr:hover {
  background: var(--cards-rows-etc-hover);
}

.leaderboard-table {
  width: 100%;
}

.leaderboard-table tr th:nth-child(1),
.leaderboard-table tr td:nth-child(1) {
  width: 9%;
}

.leaderboard-table tr th:nth-child(2),
.leaderboard-table tr td:nth-child(2) {
  width: 14%;
  text-wrap: balance;
}

.leaderboard-table tr th:nth-child(3),
.leaderboard-table tr td:nth-child(3) {
  width: 7%;
}

.leaderboard-table tr th:nth-child(4),
.leaderboard-table tr td:nth-child(4) {
  width: 11%;
}

.leaderboard-table tr th:nth-child(5),
.leaderboard-table tr td:nth-child(5) {
  width: 9%;
}

.leaderboard-table tr th:nth-child(6),
.leaderboard-table tr td:nth-child(6) {
  width: 12%;
}

.leaderboard-table tr th:nth-child(7),
.leaderboard-table tr td:nth-child(7) {
  width: 11%;
}

.leaderboard-table tr th:nth-child(8),
.leaderboard-table tr td:nth-child(8) {
  width: 11%;
}

.leaderboard-table tr th:nth-child(9),
.leaderboard-table tr td:nth-child(9) {
  width: 18%;
}

.empty-state div {
  border-radius: 0% !important;
  border-color: var(--base-fg-color) !important;
}

[data-case="normal"] h5 {
  text-transform: none !important;
}
